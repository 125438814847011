.input-group {
  margin-bottom: 15px;
}
.input-label {
  display: block;
  margin-bottom: 4px;
  font-size: small;
  color: #fff;
}
.input-container {
  position: relative;
}
.input-icon {
  position: absolute;
  font-size: 17px;
  top: 50%;
  transform: translate(0, -50%);
  left: 15px;
  border-right: 1px solid #131d445c;
  padding-right: 10px;
  color: grey;
}
.input-box {
  width: 100%;
  padding: 10px 0 10px 55px;
  border: none;
  outline: none;
  background-color: #fff;
  border-radius: 5px;
  color: grey;
  font-size: 16px;
}
.password-toggle-icon {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: grey;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -45%);
}
.error-message {
  margin-top: 5px;
  display: block;
  font-size: 14px;
  color: red;
}
